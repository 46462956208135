<template>
  <div id="preview">
    <div class="preview-content" v-html="content"></div> <!-- 渲染传递过来的内容 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: '', // 存储预览内容
    };
  },
  mounted() {
    // 动态添加 <meta> 标签
    const metaViewport = document.createElement("meta");
    metaViewport.name = "viewport";
    metaViewport.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
    document.head.appendChild(metaViewport);

    const metaAppCapable = document.createElement("meta");
    metaAppCapable.name = "apple-mobile-web-app-capable";
    metaAppCapable.content = "yes";
    document.head.appendChild(metaAppCapable);

    const metaStatusBar = document.createElement("meta");
    metaStatusBar.name = "apple-mobile-web-app-status-bar-style";
    metaStatusBar.content = "black-translucent";
    document.head.appendChild(metaStatusBar);

    // 获取 URL 中的 "content" 参数
    const params = new URLSearchParams(this.$route.query);
    const encodedContent = params.get("content");

    if (encodedContent) {
      // 解码 HTML 内容
      this.content = decodeURIComponent(encodedContent);
    }
  },
};
</script>

<style scoped>
#preview {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100vh; /* 占满屏幕高度 */
  width: 100vw; /* 占满屏幕宽度 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 防止出现滚动条 */
}

/* 适配所有设备，内容自适应屏幕 */
.preview-content {
  height: 100%; /* 内容高度自适应屏幕 */
  width: 100%; /* 内容宽度自适应屏幕 */
  margin: 0; /* 去掉外边距 */
  padding: 35px; /* 添加适当内边距 */
  box-sizing: border-box; /* 包括边框和内边距 */
  background-color: #fff; /* 可选：背景色 */
  white-space: pre-wrap; /* 保持文本换行 */
  word-wrap: break-word; /* 超长单词换行 */
  font-size: 16px; /* 小屏适配的字体 */
  overflow-y: auto; /* 超出内容可滚动 */
}
body {
  margin: 0; /* 去掉默认外边距 */
  padding: 0; /* 去掉默认内边距（可选） */
}
</style>