import request from '@/utils/request';

// 登录接口
export function getCode(data) {
  return request({
    url: '/generateShortUrlQRCode', // 生成二维码的接口
    method: 'post',
    data, // 确保请求体包含 data
    responseType: 'arraybuffer', // 确保返回的是二进制流
  });
}

