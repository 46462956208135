<template>
  <div id="edit">
    <h1>富文本编辑器 - 编辑与预览</h1>
    <div class="editor-preview-container">
      <!-- 左侧编辑器 -->
      <div class="editor-wrapper">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor class="editor text-style" v-model="html" :defaultConfig="editorConfig" :mode="mode"
          @onCreated="onCreated" />
      </div>

      <!-- 右侧预览 -->
      <div class="preview-wrapper">
        <div class="preview-screen text-style">
          <div class="preview-content" v-html="html"></div>
        </div>
      </div>
    </div>

    <!-- 生成预览链接 -->
    <div class="generate-link">
      <button @click="generatePreviewLink">生成二维码</button>
      <div v-if="previewLink">
        <!-- <p>访问预览：<a :href="previewLink" target="_blank">{{ previewLink }}</a></p> -->
      </div>
    </div>
    <div v-if="qrCode" class="qr-code-container">
    <p>预览二维码：</p>
    <img :src="qrCode" alt="预览二维码" class="qr-code-image" />
    </br>
    <button @click="downloadQRCode">下载二维码</button>
  </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getCode } from "@/api";
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
      previewLink: null, // 用于存储生成的预览链接
      qrCode: null, // 用于存储二维码数据（Base64 格式）
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 必须使用 Object.seal()，否则会报错
    },
    async generatePreviewLink() {
        // 将编辑内容作为 URL 参数传递
        const encodedHtml = encodeURIComponent(this.html); // 编码 HTML 内容
        const baseUrl = 'http:/172.20.10.2:8081';
        const proUrl='http://plumtau.cn';
        this.previewLink = `${proUrl}/preview?content=${encodedHtml}`;

        // 构造请求体
        const requestBody = {
          url: this.previewLink, // 符合后端期望的格式
        };

        // 调用后端接口生成短链接
        const response = await getCode(requestBody); // 假设 getCode 是 Axios 封装的函数
        // 将二进制数据转换为 Base64 URL
        const base64Image = `data:image/png;base64,${btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), "")
        )}`;

        // 更新二维码数据
        this.qrCode = base64Image;
    },

    // 新增的跳转到另一个页面的函数
    goToPreviewPage() {
      const encodedHtml = encodeURIComponent(this.html); // 编码 HTML 内容
      this.$router.push(`/preview?content=${encodedHtml}`); // 跳转并传递 URL 参数
    },
       // 下载二维码图片
       downloadQRCode() {
      const link = document.createElement("a");
      link.href = this.qrCode; // 设置图片的 Base64 数据为下载链接
      link.download = "preview-qrcode.png"; // 设置下载的文件名
      link.click(); // 自动触发下载
    }
  },
  mounted() {
      this.html = "";
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 销毁编辑器实例
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style>
#edit {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 编辑与预览的整体布局 */
.editor-preview-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

/* 左侧编辑器样式 */
.editor-wrapper {
  width: 45%;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.editor {
  height: 800px;
  width: 380px;
  overflow-y: auto;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  /* 去掉默认外边距 */
  padding: 0;
  /* 去掉默认内边距（可选） */
}

/* 右侧预览区样式 */
.preview-wrapper {
  width: 45%;
  text-align: center;
  padding-top: 90px;
}

.preview-screen {
  height: 800px;
  width: 380px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* 通用文本样式，应用于编辑区和预览区 */
.text-style {
  text-align: center;
  /* 文本居中 */
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.preview-content {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* 生成预览链接按钮样式 */
.generate-link {
  margin-top: 20px;
}

/* 新增按钮的样式 */
.redirect-button {
  margin-top: 20px;
}
.qr-code-container {
  margin-top: 20px;
  text-align: center;
}

.qr-code-image {
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>