// src/utils/request.js
import axios from 'axios';

// 创建 Axios 实例
const service = axios.create({
  baseURL: 'http://plumtau.cn/kyh-generate-qr-code/api', // 替换为你的后端接口根地址
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送前可以添加请求头，比如添加 Token
    const token = localStorage.getItem('token'); // 示例：从 localStorage 获取 Token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
// service.interceptors.response.use(
//   (response) => {
//     const res = response.data;
//     // 自定义错误码处理
//     if (res.code !== 200) {
//       // 示例：统一错误提示
//       console.error(res.message || 'Error');
//       return Promise.reject(new Error(res.message || 'Error'));
//     }
//     return res;
//   },
//   (error) => {
//     console.error('Request error:', error.message);
//     return Promise.reject(error);
//   }
// );

export default service;
