import Vue from 'vue'
import VueRouter from 'vue-router'
import Preview from '../views/Preview.vue'
import Edit from'../views/EditView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/preview',
    name: 'preview',
    component: Preview
  },
  {
    path: '/edit',
    name: 'edit',
    component: Edit
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
